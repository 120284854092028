import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AzeroLogo from "../../../assets/images/alephzero-logo.jpeg"
import NetworkDetails from "../../components/network-details";

const Azero = () => (
  <Layout>
    <Seo title="Aleph Zero" />
    <NetworkDetails
      logo={AzeroLogo}
      name="azero"
      commission="5%"
      totalStake="78,595 - AZERO"
      address="5F73AtLJPKJfyyenPLjfx4PqHbw4s6yCfysMFfkXfsWv15nm"
      comingSoon={false}
    />
    <Link
      className="orange-button"
      style={{
        maxWidth: `45%`,
        margin: `80px auto 0`,
        textAlign: `center`,
        display: `block`,
        textDecoration: `none`
      }}
      to="/#networks"
    >
      Back to homepage
    </Link>
  </Layout>
)

export default Azero
