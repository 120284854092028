import * as React from "react"
import { Link } from "gatsby"
import "./network-details.css"

const SelectAndCopyText = element => {
  var doc = document,
    text = doc.getElementById(element),
    range,
    selection;
  if (doc.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text);

    text && text.textContent && navigator.clipboard.writeText(text.textContent).then(function() {
      /* clipboard successfully set */
    }, function() {
      /* clipboard write failed */
    });

    selection.removeAllRanges();
    selection.addRange(range);
  }
}

const NetworkDetails = ({ logo, name, commission, totalStake, address, comingSoon}) => (
  <div className={`network-details ` + (comingSoon && `coming-soon`)}>
    <h1>
      <img src={logo} className="network-logo" />
      <span>Stake your {name} with us!</span>
    </h1>
    <ul className="details">
      <li>
        <span className="label">Commission</span>
        <span className="value">{commission}</span>
      </li>
      <li>
        <span className="label">Total Stake</span>
        <span className="value">{totalStake}</span>
      </li>
    </ul>
    <div className="node-address">
      <span className="label" onClick={() => SelectAndCopyText("node-address-value")}>Address (click to copy)</span>
      <span className="value" id="node-address-value">{address}</span>
    </div>
  </div>
)

export default NetworkDetails
